import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { IAttendeeInfo } from '../../types';
import { X, Download, UserX2, Users } from 'lucide-react';
import useOutsideClick from '../../hooks/useOutsideClick';
import DownloadMenu from './DownloadMenu';

interface Stats {
  total: number;
  attended: number;
  notAttended: number;
  accepted: number;
}

interface AvatarGroupPopoverProps {
  attendees: IAttendeeInfo[];
  onClose: () => void;
  companyId: string;
  interestId: string;
  interestName: string;
  stats: Stats;
  initialFilter?: 'total' | 'attended';
  showTotalOnly?: boolean;
}

const AvatarGroupPopover: React.FC<AvatarGroupPopoverProps> = ({
  attendees,
  onClose,
  companyId,
  interestId,
  interestName,
  stats,
  initialFilter = 'total',
  showTotalOnly = false
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const downloadButtonRef = useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    setTimeout(() => setIsVisible(true), 50);
  }, []);

  useOutsideClick(popoverRef, () => {
    if (isVisible) {
      handleClose();
    }
  });

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  const toggleDownloadMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDownloadMenuOpen(!isDownloadMenuOpen);
  };

  const filteredAttendees = initialFilter === 'attended'
    ? attendees.filter(a => a.didAttend).sort((a, b) => a.attendeeName.localeCompare(b.attendeeName))
    : attendees.sort((a, b) => a.attendeeName.localeCompare(b.attendeeName));

  const getExportMeetingInfo = () => {
    return {
      attendees: filteredAttendees,
      companyId,
      interestId,
      interestName,
      meetingId: 'filtered',
      meetingTimeUtc: new Date().toISOString(),
      meetingStartDateTime: new Date().toISOString(),
      meetingEndDateTime: new Date().toISOString(),
      totalParticipantCount: filteredAttendees.length.toString(),
    };
  };

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-12 px-4 text-center">
      {initialFilter === 'attended' ? (
        <>
          <UserX2 size={48} className="text-red-500 mb-3" />
          <h3 className="text-lg font-medium text-red-600 mb-1">No Attendees Found</h3>
          <p className="text-red-500 max-w-sm">
            No one has attended this meeting yet. Check back later or view all invited participants.
          </p>
        </>
      ) : (
        <>
          <Users size={48} className="text-gray-400 mb-3" />
          <h3 className="text-lg font-medium text-gray-900 mb-1">No Participants</h3>
          <p className="text-gray-500 max-w-sm">
            There are no participants in this meeting yet. Try checking back later.
          </p>
        </>
      )}
    </div>
  );

  return createPortal(
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-[9998]"
        onClick={handleClose}
      ></div>
      <div
        ref={popoverRef}
        className={`fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-full max-w-md shadow-2xl transition-all duration-300 ease-in-out z-[9999] ${isVisible ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
          }`}
        style={{ maxHeight: '90vh', display: 'flex', flexDirection: 'column' }}
      >
        <header className="bg-indigo-600 text-white px-4 py-3 rounded-t-lg flex items-center justify-between min-h-[48px]">
          <h3 className="text-lg font-semibold leading-tight line-clamp-1 flex-1">
            {interestName} - {initialFilter === 'attended' ? 'Attended' : 'All'} Attendees
          </h3>
          <div className="flex items-center gap-2 flex-shrink-0">
            {/* <div className="relative">
              <button
                ref={downloadButtonRef}
                onClick={toggleDownloadMenu}
                className="bg-white/10 hover:bg-white/20 text-white px-2 py-1 rounded-md flex items-center text-sm transition-colors duration-200 whitespace-nowrap"
              >
                <Download size={14} className="mr-1" />
                Download
              </button>
              {isDownloadMenuOpen && (
                <div className="absolute right-0 mt-2 z-50">
                  <DownloadMenu
                    meetingInfo={getExportMeetingInfo()}
                    onClose={() => setIsDownloadMenuOpen(false)}
                  />
                </div>
              )}
            </div> */}
            <button
              onClick={handleClose}
              className="text-white/80 hover:text-white transition-colors duration-200 p-1"
            >
              <X size={20} />
            </button>
          </div>
        </header>

        <div className="flex-grow overflow-y-auto px-6 py-4">
          {filteredAttendees.length > 0 ? (
            <div className="space-y-3">
              {filteredAttendees.map((attendee) => (
                <div
                  key={attendee.attendeeId}
                  className="bg-gray-50 p-3 rounded-lg"
                >
                  <div className="flex items-center">
                    <div className="relative">
                      <div className="w-8 h-8 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center font-semibold">
                        {attendee.attendeeName.charAt(0).toUpperCase()}
                      </div>
                      <div
                        className={`absolute -top-1 -right-1 w-3 h-3 rounded-full border-2 border-white ${attendee.didAttend
                          ? 'bg-gradient-to-br from-green-300 to-green-400'
                          : 'bg-gradient-to-br from-red-300 to-red-400'
                          }`}
                      ></div>
                    </div>
                    <div className="ml-3">
                      <span className="font-medium text-gray-800">
                        {attendee.attendeeName}
                      </span>
                      {attendee.didAccept && (
                        <div className="text-amber-500 flex items-center text-xs mt-0.5">
                          ✓ Accepted Invitation
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <EmptyState />
          )}
        </div>

        <footer className="bg-gray-100 px-6 py-4 rounded-b-lg flex justify-end">
          <button
            onClick={handleClose}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200 flex items-center"
          >
            <X size={20} className="mr-2" />
            Close
          </button>
        </footer>
      </div>
    </>,
    document.body
  );
};

export default AvatarGroupPopover;