import { Card, makeStyles } from '@fluentui/react-components'
import Meeting, { IMeetingInfo } from './Meeting'
import { CardHeader } from '@fluentui/react-components'
import { DocumentCard, Overlay } from '@fluentui/react'
import { DefaultButton } from '@fluentui/react/lib/Button'
import { IStyle, classNamesFunction } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import MeetingsEmptyState from './MeetingsEmptyState'
import _ from 'lodash'

// const useStyles = makeStyles({
//   card: {
//     width: '311px',
//     backgroundColor: 'white',
//     height: '750px',
//   },
// })

export interface IMeetingsProps {
  upcomingMeetings: IMeetingInfo[]
  pastMeetings: IMeetingInfo[]
}

interface IOverlayExampleStyles {
  root: IStyle
}

const exampleStyles: IOverlayExampleStyles = {
  root: [
    'OverlayExample-content',
    {
      background: '#1B91DF',
      color: 'white',
      left: '0',
      padding: '20px',
      position: 'absolute',
      right: '0',
      borderRadius: '0.5rem',
      marginLeft: '-37px',
      width: '21rem',
      boxShadow: '0 13px 17px 0 rgba(0, 0, 0, .13)',
    },
  ],
}

export default function Meetings(props: IMeetingsProps) {
  let [activeTab, setActiveTab] = React.useState<string>('upcoming')
  // const styles = useStyles()

  const getClassNames = classNamesFunction<{}, IOverlayExampleStyles>()
  const classNames = getClassNames(exampleStyles, {})
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 700)

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 700)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const inactiveTabStyle = {
    backgroundColor: '#5ca1db',
    color: 'white',
    border: '1px solid #5ca1db',
  }
  return (
    <div>
      <Card
        style={{
          padding: '24px 16px 4px 22px',
          gap: '24px',
          border: '1px solid #D6D6D6',
          background: 'white',
          overflow: 'visible',
          width: isLargeScreen ? '311px' : '19rem',
          backgroundColor: 'white',
          height: isLargeScreen ? '750px' : '33rem',
        }}
      >
        <CardHeader
          header={
            <div className="ms-fontSize-24 ms-fontWeight-semibold leading-7 inline-flex gap-8">
              <div>Meetings</div>
            </div>
          }
        />
        <DocumentCard>
          <Overlay>
            <div className={classNames.root}>
              <DefaultButton
                onClick={() => {
                  setActiveTab('upcoming')
                }}
                text="Upcoming"
                style={_.extend({ borderRadius: '0.5rem' }, activeTab === 'upcoming' ? {} : inactiveTabStyle)}
              />
              <DefaultButton
                onClick={() => {
                  setActiveTab('past')
                }}
                text="Past"
                style={_.extend(
                  {
                    borderRadius: '0.5rem',
                    marginLeft: '0.5rem',
                  },
                  activeTab === 'past' ? {} : inactiveTabStyle
                )}
              />
            </div>
          </Overlay>
        </DocumentCard>
        <div className="mt-10"></div>
        <div className="overflow-auto max-h-[calc(100vh-235px)] flex flex-col gap-6"
        // style={{ overflowY: 'auto', overflowX: 'inherit', overflow: 'auto', maxHeight: 'calc(100vh - 235px)', display: 'flex', flexDirection: 'column', gap: '24px' }}
        >
          {activeTab === 'upcoming' &&
            props.upcomingMeetings?.map((item: IMeetingInfo, i: number) => (
              <Meeting
                key={i}
                attendees={item.attendees}
                interestName={item.interestName}
                atUtc={item.atUtc}
                isUpComing={true}
              />
            ))}

          {activeTab === 'upcoming' && props.upcomingMeetings && props.upcomingMeetings.length === 0 && (
            <MeetingsEmptyState />
          )}

          {activeTab === 'past' &&
            (props.pastMeetings.length !== 0) && props.pastMeetings?.map((item: IMeetingInfo, i: number) => (
              <Meeting
                key={i}
                attendees={item.attendees}
                interestName={item.interestName}
                atUtc={item.atUtc}
                isUpComing={false}
              />
            ))}

          {activeTab === 'past' && props.pastMeetings && props.pastMeetings.length === 0 && <MeetingsEmptyState />}
        </div>
      </Card>
    </div>
  )
}