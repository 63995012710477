import { Field, Button } from '@fluentui/react-components'
import { TimePicker, TimePickerProps, formatDateToTimeString } from '@fluentui/react-timepicker-compat'
import { useEffect, useState } from 'react'
import { useAppStateStore } from '../../store'
import moment from 'moment'
import { apiGet, apiPost } from '../../services/fetch/apiClient'
import { logger } from '../../components/App'
import TimezonePicker, { SelectTimezoneProps } from '../../components/TimeZonePicker'

export default function WorkingHours() {
  const [availableFromError, setAvailableFromError] = useState<string>('')
  const [availableUntilError, setAvailableUntilError] = useState<string>('')
  const [timezoneError, setTimezoneError] = useState<string>('')

  const appState = useAppStateStore()

  const dateFromHour = (hour: number) => {
    return new Date(moment().set({ hour: hour, minute: 0 }).format('YYYY-MM-DD HH:mm'))
  }

  type InterestModel = {
    id: string
    name: string
  }

  type UserPreferenceModel = {
    workingHours: {
      from: number
      until: number
      timeZone: string
    }
    nextMeetingInDays: number
    interests: InterestModel[]
  }

  const timezoneStyles = {
    timezoneContainer: {
      width: 220,
      minHieght: 40,
      color: 'black',
      dir: "ltl",
      textAlign: "left",
      marginLeft: 0,
      marginRight: 0,
      position: "relative",
      justifyContent: 'center',
      alignItems: 'center',
    },

    timezoneContainerLabel: {
      color: 'white',
    },
    timezoneContainerSelect: {
      color: 'black',
    }
  }

  const timezoneInfo = {
    id: "Timezone",
    name: "WaterCooler Timezone Picker",
    label: "Select Timezone",
    isSearchable: true
  }

  useEffect(() => {
    getUsersTimezoneFromDB()
  }, [])

  const getUsersTimezoneFromDB = async () => {
    try {
      const userPreferences = await apiGet<UserPreferenceModel>('settings', appState.identity.accessToken)
      appState.setTimeZone(userPreferences.workingHours.timeZone);
    } catch (err: any) {
      logger.debug(err)
    }
  }
  const setWorkingHours = async (from: number, until: number, timezone: string) => {
    try {
      await apiPost('settings/set-working-hours', appState.identity.accessToken, {
        availableFrom: from,
        availableUntil: until,
        timezone: timezone
      })

      appState.setWorkingHours(from, until, timezone)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  const onAvailableFromChange: TimePickerProps['onTimeChange'] = (_ev, data) => {
    const fromHour = moment(data.selectedTime).hour()
    if (appState.profile.workingHours.until <= fromHour) {
      setAvailableFromError('From time should be lesser than To time')
      return
    }

    setAvailableFromError('')
    setWorkingHours(fromHour, appState.profile.workingHours.until, appState.profile.workingHours.timezone)
  }

  const onAvailableUntilChange: TimePickerProps['onTimeChange'] = (_ev, data) => {
    const untilHour = moment(data.selectedTime).hour()
    if (untilHour <= appState.profile.workingHours.from) {
      setAvailableUntilError('To time should be greater than From time')
      return
    }

    setAvailableUntilError('')
    setWorkingHours(appState.profile.workingHours.from, untilHour, appState.profile.workingHours.timezone)
  }

  const setSelectedTimezoneValue: SelectTimezoneProps['onChange'] = (selectedDplTimezoneLabel, selectedDplTimezoneValue) => {
    if (selectedDplTimezoneValue == "" || selectedDplTimezoneValue == undefined || selectedDplTimezoneValue == null) {
      setTimezoneError('Please Select the Timezone')
      return
    }
    else {
      appState.setTimeZone(selectedDplTimezoneValue);
    }
    setTimezoneError('')
    setWorkingHours(appState.profile.workingHours.from, appState.profile.workingHours.until, selectedDplTimezoneValue)
  }

  return (
    <>
      <div className="ms-Grid">
        {/* Mobile and Medium view */}
        <div className="ms-Grid-row" style={{ display: 'block' }}>
          <div className="ms-Grid-col ms-sm12 ms-hiddenXlUp">
            <div style={{ marginBottom: '1rem' }}>
              <Field>
                <span style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>From</span>
                <TimePicker
                  increment={60}
                  hourCycle="h11"
                  freeform={false}
                  className="custom-timepicker *:min-w-0"
                  defaultSelectedTime={dateFromHour(appState.profile.workingHours.from)}
                  defaultValue={formatDateToTimeString(dateFromHour(appState.profile.workingHours.from))}
                  onTimeChange={onAvailableFromChange}
                  //style={{ minWidth: '130px', maxWidth: '130px', fontWeight: 'bold' }}
                  style={{ width: '100%', minWidth: '0', maxWidth: '150px', fontWeight: 'bold' }}
                />
                {availableFromError && <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{availableFromError}</div>}
              </Field>
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <Field>
                <span style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>To</span>
                <TimePicker
                  increment={60}
                  hourCycle="h11"
                  freeform={false}
                  className="custom-timepicker *:min-w-0"
                  defaultSelectedTime={dateFromHour(appState.profile.workingHours.until)}
                  defaultValue={formatDateToTimeString(dateFromHour(appState.profile.workingHours.until))}
                  onTimeChange={onAvailableUntilChange}
                  // style={{ minWidth: '130px', maxWidth: '130px', fontWeight: 'bold' }}
                  style={{ width: '100%', minWidth: '0', maxWidth: '150px', fontWeight: 'bold' }}
                />
                {availableUntilError && <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{availableUntilError}</div>}
              </Field>
            </div>
            <div>
              <Field>
                <span style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>TimeZone</span>
                <TimezonePicker
                  timezoneInfo={timezoneInfo}
                  timezoneStyles={{
                    timezoneContainer: {
                      //width: '150px',
                      //minWidth: '150px',
                      maxWidth: '220px',
                      minHeight: '40px',
                      color: 'black',
                      dir: "ltl",
                      textAlign: "left",
                      marginLeft: 0,
                      marginRight: 0,
                      display: 'flex',
                      //alignItems: 'center',
                      flexDirection: 'column'
                    },
                    timezoneContainerLabel: {
                      color: 'black',
                      width: '150px',
                      display: 'block',
                      fontWeight: 'bold'
                    },
                    timezoneContainerSelect: {
                      width: '150px',
                      minWidth: '150px',
                      maxWidth: '150px',
                      color: 'black',
                      overflow: 'visible',
                      position: 'absolute',
                      left: 0,
                      padding: '4px 8px',
                      fontWeight: 'bold'
                    }
                  }}
                  onChange={(label, value) => setSelectedTimezoneValue(label, value)}
                  selectedTimeZone={appState.profile.workingHours.timezone ? appState.profile.workingHours.timezone : appState.profile.usersCurrentTimezone}
                />
                {timezoneError && <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{timezoneError}</div>}
              </Field>
            </div>
          </div>

          {/* Large and above view */}
          <div className="ms-Grid-col ms-sm12 ms-hiddenLgDown" style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: '0 24px 0 0', display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '8px', fontWeight: 'bold' }}>From</span>
              <div style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}>
                <TimePicker
                  increment={60}
                  hourCycle="h11"
                  freeform={false}
                  defaultSelectedTime={dateFromHour(appState.profile.workingHours.from)}
                  defaultValue={formatDateToTimeString(dateFromHour(appState.profile.workingHours.from))}
                  onTimeChange={onAvailableFromChange}
                  style={{ width: '80px', minWidth: '80px', maxWidth: '80px', fontWeight: 'bold' }}
                />
              </div>
            </div>
            <div style={{ padding: '0 24px 0 0', display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '8px', fontWeight: 'bold' }}>To</span>
              <div style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}>
                <TimePicker
                  increment={60}
                  hourCycle="h11"
                  freeform={false}
                  defaultSelectedTime={dateFromHour(appState.profile.workingHours.until)}
                  defaultValue={formatDateToTimeString(dateFromHour(appState.profile.workingHours.until))}
                  onTimeChange={onAvailableUntilChange}
                  style={{ width: '80px', minWidth: '80px', maxWidth: '80px', fontWeight: 'bold' }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '8px', fontWeight: 'bold' }}>TimeZone</span>
              <div style={{ width: '220px', minWidth: '220px', maxWidth: '220px', position: 'relative' }}>
                <TimezonePicker
                  timezoneInfo={timezoneInfo}
                  timezoneStyles={{
                    timezoneContainer: {
                      width: '220px',
                      minWidth: '220px',
                      maxWidth: '220px',
                      height: '32px',
                      minHeight: '32px',
                      color: 'black',
                      dir: "ltl",
                      textAlign: "left",
                      marginLeft: 0,
                      marginRight: 0,
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'visible'
                    },
                    timezoneContainerLabel: {
                      color: 'black',
                      width: '220px',
                      display: 'block',
                      fontWeight: 'bold'
                    },
                    timezoneContainerSelect: {
                      width: '220px',
                      minWidth: '220px',
                      maxWidth: '220px',
                      color: 'black',
                      overflow: 'visible',
                      position: 'absolute',
                      left: 0,
                      padding: '4px 8px',
                      fontWeight: 'bold'
                    }
                  }}
                  onChange={(label, value) => setSelectedTimezoneValue(label, value)}
                  selectedTimeZone={appState.profile.workingHours.timezone ? appState.profile.workingHours.timezone : appState.profile.usersCurrentTimezone}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
