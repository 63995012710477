import React, { useEffect, useState } from 'react';
import { apiGet, apiPostResponse } from '../../services/fetch/apiClient';
import { useAppStateStore, OnboardedResult, UserDashboard } from '../../store';
import { logger } from '../../components/App';

const Notifications: React.FC = () => {
    const [questions, setQuestions] = useState<{ [key: string]: string[] }>({});
    const [fetchedTopics, setFetchedTopics] = useState<string[]>([]);
    const [dashboardResult, setDashboardResult] = useState<UserDashboard | null>(null);

    const appState = useAppStateStore();

    useEffect(() => {
        const ensureOnboarded = async () => {
            try {
                const onboardedResult = await apiPostResponse<OnboardedResult>(
                    'landing-page/ensure-onboarded',
                    appState.identity.accessToken,
                    null
                );

                if (!onboardedResult.isNewCompany && !onboardedResult.isFirstLogin) {
                    const dashboardResult = await apiGet<UserDashboard>(
                        'landing-page/user-dashboard',
                        appState.identity.accessToken
                    );
                    setDashboardResult(dashboardResult);
                }
            } catch (err: any) {
                logger.debug(err);
            }
        };

        ensureOnboarded();
    }, [appState.identity.accessToken]);

    async function generateIceBreakingQuestions(topic: string) {
        const apiKey = "f36bfa9de4964347827d0fec0a3a3f5b";
        const deploymentName = "wc-gpt-35-turbo";
        try {
            const response = await fetch(`https://wc-openai-test.openai.azure.com/openai/deployments/${deploymentName}/chat/completions?api-version=2024-10-01-preview`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "api-key": apiKey
                },
                body: JSON.stringify({
                    messages: [
                        { role: "user", content: `Generate 3 interesting questions about the topic: ${topic}. Please do not add any other text apart from the questions. Please use serial numbering when returning the questions.` }
                    ],
                    max_tokens: 100,
                    temperature: 0.7,
                    top_p: 1.0,
                    frequency_penalty: 0.0,
                    presence_penalty: 0.0
                })
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const jsonResponse = await response.json();
            const text = jsonResponse.choices[0].message.content.trim();

            return text.split("\n").map((q: string) => q.replace(/^\d+\.\s*/, ''));
        } catch (error) {
            console.error("Failed to fetch ice-breaking questions:", error);
            return [];
        }
    }

    useEffect(() => {
        const fetchQuestions = async () => {
            if (!dashboardResult) return;

            const upcomingMeetings = dashboardResult?.upcomingMeetings || [];
            const newQuestions = { ...questions };
            let hasNewTopics = false;
            const newFetchedTopics = [...fetchedTopics];

            for (const meeting of upcomingMeetings) {
                if (meeting.interestName && !newFetchedTopics.includes(meeting.interestName)) {
                    hasNewTopics = true;
                    const generatedQuestions = await generateIceBreakingQuestions(meeting.interestName);
                    newQuestions[meeting.interestName] = generatedQuestions;
                    newFetchedTopics.push(meeting.interestName);
                }
            }

            if (hasNewTopics) {
                setQuestions(prev => ({ ...prev, ...newQuestions }));
                setFetchedTopics(newFetchedTopics);
            }
        };

        fetchQuestions();
    }, [dashboardResult, questions, fetchedTopics]);

    const upcomingMeetings = dashboardResult?.upcomingMeetings || [];

    return (
        <div style={{
            backgroundColor: 'rgb(239, 245, 249)',
            padding: '20px',
            overflowX: 'hidden',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center'
        }}>
            {upcomingMeetings.length === 0 ? (
                <p>You don't have any new meetings.</p>
            ) : (
                <ul>
                    {upcomingMeetings.map((meeting) => (
                        <li key={meeting.id}>
                            <p style={{ fontStyle: 'italic', color: 'gray' }}>All ice-breaking questions are powered by Microsoft Azure AI, with built-in safeguards to ensure respectful and appropriate language at all time.</p>
                            <strong>Ice breaking Questions for Topic:</strong> {meeting.interestName}
                            <ul>
                                {questions[meeting.interestName]?.length > 0 ? (
                                    questions[meeting.interestName].map((q, index) => (
                                        <li key={index}>{q}</li>
                                    ))
                                ) : (
                                    <li>Loading questions...</li>
                                )}
                            </ul>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Notifications;
