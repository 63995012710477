import React, { useMemo, useState } from 'react';
import { MultiValue, ActionMeta } from 'react-select';
import { Trophy, Crown, Medal, Users, ChevronLeft, ChevronRight, Search, UserPlus, XCircle, Info, ExternalLink, Download, Calendar, CheckCircle2, BarChart3, ChevronDown, Check, X } from 'lucide-react';
import { motion } from 'framer-motion';
import { IMeetingInfo } from '../../../types';
import LeaderboardExport from './LeaderboardExport';
import Select, { StylesConfig, GroupBase, components } from 'react-select';
import * as Tooltip from '@radix-ui/react-tooltip';
import RankBadge from './RankBadge';
import MetricCard from './MetricCard';

type SelectOption = {
  value: string;
  label: string;
};

interface LeaderboardProps {
  meetings: IMeetingInfo[];
  attendeeStats: {
    name: string;
    email: string;
    topic: string;
    totalAttendanceTime: number;
    totalMeetings: number;
    attendedMeetings: number;
    acceptedMeetings: number;
  }[];
  currentView: "leaderboard";
  selectedNames?: MultiValue<SelectOption>;
  selectedGroups?: MultiValue<SelectOption>;
  onNameFilterChange?: (newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => void;
  onGroupFilterChange?: (newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => void;
  style?: React.CSSProperties;
}

interface AttendeeStats {
  name: string;
  email: string;
  topic: string;
  timezone?: string;
  totalAttendanceTime: number;
  totalMeetings: number;
  attendedMeetings: number;
  acceptedMeetings: number;
}

const Leaderboard: React.FC<LeaderboardProps> = ({ meetings, attendeeStats, currentView, selectedNames = [], selectedGroups = [], onNameFilterChange, onGroupFilterChange, style }) => {
  // Basic data processing
  const calculateUserMetrics = (meetings: IMeetingInfo[]) => {
    const userMetrics = new Map();

    meetings.forEach(meeting => {
      meeting.attendees.forEach(attendee => {
        const email = attendee.attendeeEmail;
        if (!userMetrics.has(email)) {
          userMetrics.set(email, {
            name: attendee.attendeeName,
            email: email,
            group: attendee.attendeeGroup || 'Other',
            invitedCount: 0,
            acceptedCount: 0,
            attendedCount: 0,
            notAttendedCount: 0,
            acceptedNotParticipated: 0,
            attendedVsAcceptedRatio: 0,
            attendedVsInvitedRatio: 0,
            normalizedScore: 0
          });
        }

        const metrics = userMetrics.get(email);
        metrics.invitedCount++;
        if (attendee.didAccept) metrics.acceptedCount++;
        if (attendee.didAttend) metrics.attendedCount++;
      });
    });

    // Calculate derived metrics
    userMetrics.forEach(metrics => {
      metrics.notAttendedCount = metrics.acceptedCount - metrics.attendedCount;

      // Calculate not participated percentage based on total invited count
      metrics.acceptedNotParticipated = Math.round(((metrics.acceptedCount - metrics.attendedCount) / metrics.invitedCount) * 100);

      // Calculate percentages based on total invited count
      metrics.acceptedVsInvitedRatio = (metrics.acceptedCount / metrics.invitedCount) * 100;
      metrics.attendedVsInvitedRatio = (metrics.attendedCount / metrics.invitedCount) * 100;
      metrics.attendedVsAcceptedRatio = metrics.acceptedCount > 0
        ? (metrics.attendedCount / metrics.acceptedCount) * 100
        : 0;

      // Calculate Engagement Score using the formula
      const score = (metrics.acceptedCount * 1) +
        (metrics.attendedCount * 2) -
        ((metrics.acceptedCount - metrics.attendedCount) * 1);

      // Normalize the score by dividing by total invited count and max possible points per meeting (3)
      metrics.normalizedScore = Math.round((score / metrics.invitedCount / 3) * 100);
    });

    return Array.from(userMetrics.values())
      .sort((a, b) => {
        // First sort by normalizedScore (Engagement Score) in descending order
        if (b.normalizedScore !== a.normalizedScore) {
          return b.normalizedScore - a.normalizedScore;
        }
        // If engagement scores are equal, sort by name alphabetically
        return a.name.localeCompare(b.name);
      })
      .map((metrics, index) => ({
        ...metrics,
        rank: index + 1
      }));
  };

  const userMetrics = useMemo(() => calculateUserMetrics(meetings), [meetings]);

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    rank: '',
    name: '',
    email: '',
    acceptedCount: '',
    attendedCount: '',
    acceptedNotParticipated: '',
    attendedVsAcceptedRatio: '',
    normalizedScore: ''
  });
  const itemsPerPage = 10;

  const [sortField, setSortField] = useState<string>('rank');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const originalRanks = useMemo(() => {
    const ranks = new Map();
    userMetrics.forEach((user, index) => {
      ranks.set(`${user.name}-${user.normalizedScore}`, index + 1);
    });
    return ranks;
  }, [userMetrics]);

  const handleNameFilterChange = (
    newValue: MultiValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    if (onNameFilterChange) {
      onNameFilterChange?.(newValue, actionMeta);
      setCurrentPage(1); // Reset to first page when filter changes
    }
  };

  const handleGroupFilterChange = (
    newValue: MultiValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    if (onGroupFilterChange) {
      onGroupFilterChange?.(newValue, actionMeta);
      setCurrentPage(1); // Reset to first page when filter changes
    }
  };

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const getSortIcon = (field: string) => {
    if (sortField !== field) {
      return (
        <span className="text-white opacity-50 text-lg">↕</span>
      );
    }
    return sortDirection === 'asc' ? (
      <span className="text-white text-lg">↑</span>
    ) : (
      <span className="text-white text-lg">↓</span>
    );
  };

  const getHeaderClass = (field: string) => {
    return `px-2 py-0.5 text-left text-xs font-bold text-white uppercase tracking-wider cursor-pointer transition-colors ${sortField === field
      ? 'bg-blue-600'
      : 'hover:bg-blue-600'
      }`;
  };

  const sortData = (data: any[]) => {
    return [...data].sort((a, b) => {
      let aValue = a[sortField];
      let bValue = b[sortField];

      // Handle special cases for ratio fields
      if (sortField === 'attendedVsAcceptedRatio' || sortField === 'normalizedScore') {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  };

  // Filter and search functionality
  //const [selectedNames, setSelectedNames] = useState<readonly SelectOption[]>([]);
  //const [selectedGroups, setSelectedGroups] = useState<readonly SelectOption[]>([]);

  const originalTopScores = useMemo(() => {
    return [...new Set(userMetrics.map(u => u.normalizedScore))]
      .sort((a, b) => b - a)
      .slice(0, 2);
  }, [userMetrics]);

  // Get unique groups from meetings data
  const groupOptions = useMemo(() => {
    const groupCounts = new Map();
    userMetrics.forEach(user => {
      const count = groupCounts.get(user.group) || 0;
      groupCounts.set(user.group, count + 1);
    });

    return Array.from(groupCounts.entries())
      .map(([group, count]) => ({
        value: group,
        label: `${group} (${count})`
      }))
      .sort((a, b) => a.value.localeCompare(b.value));
  }, [userMetrics]);

  // Create options from data
  const nameOptions = useMemo(() => {
    return userMetrics.map(user => ({
      value: user.email,
      label: user.name
    }));
  }, [userMetrics]);

  const filteredData = useMemo(() => {
    return userMetrics.filter(user => {
      // Apply name filters
      const matchesName = selectedNames.length === 0 ||
        selectedNames.some(selected => selected.value === user.email);

      // Apply group filters
      const matchesGroup = selectedGroups.length === 0 ||
        selectedGroups.some(selected => selected.value === user.group);

      return matchesName && matchesGroup;
    });
  }, [userMetrics, selectedNames, selectedGroups]);

  const sortedData = useMemo(() => sortData(filteredData), [filteredData, sortField, sortDirection]);

  // Pagination
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(currentPage * itemsPerPage, sortedData.length);
  const currentData = sortedData.slice(startIndex, endIndex);

  // Generate page numbers for pagination
  const getPageNumbers = () => {
    const pageNumbers = [];

    if (currentPage <= 4) {
      // If current page is 4 or less, show first 4 pages
      for (let i = 1; i <= Math.min(4, totalPages); i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage > 4 && currentPage < totalPages) {
      // If current page is beyond 4, show it with surrounding pages
      pageNumbers.push(currentPage - 1);
      pageNumbers.push(currentPage);
      pageNumbers.push(currentPage + 1);
    } else if (currentPage === totalPages) {
      // If on last page, show last 3 pages
      pageNumbers.push(totalPages - 2);
      pageNumbers.push(totalPages - 1);
      pageNumbers.push(totalPages);
    }

    // Add dots and last page if needed
    if (totalPages > 4 && currentPage <= 4) {
      pageNumbers.push('dot');
      pageNumbers.push(totalPages);
    }
    // Add first page, dots, and last page if in middle
    else if (currentPage > 4 && currentPage < totalPages - 2) {
      pageNumbers.unshift('dot');
      pageNumbers.unshift(1);
      pageNumbers.push('dot');
      pageNumbers.push(totalPages);
    }
    // Add first page and dots if near end
    else if (currentPage >= totalPages - 2 && totalPages > 4) {
      pageNumbers.unshift('dot');
      pageNumbers.unshift(1);
    }

    return pageNumbers;
  };

  // Pagination handlers
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const [showDownloadMenu, setShowDownloadMenu] = useState(false);
  const [downloadMenuPosition, setDownloadMenuPosition] = useState({ top: 0, left: 0 });
  const [selectedUserForDownload, setSelectedUserForDownload] = useState<IMeetingInfo | null>(null);

  const handleDownload = (user: any, event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    const isSmallOrMediumScreen = window.innerWidth <= 1024; // Increased breakpoint to include medium devices

    setDownloadMenuPosition({
      top: rect.top + window.scrollY - 10,
      left: isSmallOrMediumScreen ? window.innerWidth - 160 : rect.right - 147
    });
    setSelectedUserForDownload(user);
    setShowDownloadMenu(true);
  };

  // Custom styles for react-select
  const customStyles = {
    control: (base: any) => ({
      ...base,
      minHeight: '42px',
      maxHeight: '42px',
      borderRadius: '0.5rem',
      borderColor: '#E5E7EB',
      boxShadow: 'none',
      overflow: 'hidden',
      paddingLeft: '1rem',
      '&:hover': {
        borderColor: '#E5E7EB'
      }
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: '2px 8px',
      paddingLeft: '1rem',
      maxHeight: '42px',
      overflowY: 'auto',
      gap: '4px',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '6px'
      },
      '&::-webkit-scrollbar-track': {
        background: '#F3F4F6',
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#D1D5DB',
        borderRadius: '4px',
        '&:hover': {
          background: '#9CA3AF'
        }
      }
    }),
    multiValue: (base: any, { data, index }: any) => ({
      ...base,
      backgroundColor: colors[index % colors.length].bg,
      border: `1px solid ${colors[index % colors.length].border}`,
      borderRadius: '0.25rem',
      padding: '1px',
      margin: '2px',
      color: colors[index % colors.length].text,
      position: 'relative'
    }),
    multiValueLabel: (base: any, { data, index }: any) => ({
      ...base,
      color: colors[index % colors.length].text,
      padding: '0 4px',
      paddingRight: '20px',
      fontSize: '0.875rem'
    }),
    multiValueRemove: (base: any, { data, index }: any) => ({
      ...base,
      color: colors[index % colors.length].text,
      padding: '0',
      borderRadius: '50%',
      border: `1px solid ${colors[index % colors.length].text}`,
      marginLeft: '4px',
      marginRight: '2px',
      width: '14px',
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '-4px',
      right: '-2px',
      backgroundColor: 'white',
      ':hover': {
        backgroundColor: 'white',
        color: colors[index % colors.length].text,
        opacity: 0.7
      }
    }),
    clearIndicator: (base: any) => ({
      ...base,
      cursor: 'pointer',
      padding: '0',
      margin: '4px',
      borderRadius: '50%',
      border: '1px solid #EF4444',
      color: '#EF4444',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        color: '#DC2626'
      }
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#6B7280',
      ':hover': {
        color: '#374151'
      }
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#6B7280'
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 50
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: '200px',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px'
      },
      '&::-webkit-scrollbar-track': {
        background: '#F3F4F6',
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#D1D5DB',
        borderRadius: '4px',
        '&:hover': {
          background: '#9CA3AF'
        }
      }
    })
  };

  const colors = [
    { bg: '#FCE7F3', text: '#DB2777', border: '#F9A8D4' },  // Pink
    { bg: '#EDE9FE', text: '#7C3AED', border: '#DDD6FE' },  // Purple
    { bg: '#FEF3C7', text: '#D97706', border: '#FDE68A' },  // Orange
    { bg: '#DBEAFE', text: '#2563EB', border: '#BFDBFE' },  // Blue
  ];

  // Custom components for the Select
  const SelectComponents = {
    DropdownIndicator: (props: any) => {
      return (
        <components.DropdownIndicator {...props}>
          <ChevronDown className="h-4 w-4 text-gray-400" />
        </components.DropdownIndicator>
      );
    }
  };

  // Function to clear all filters
  // const clearAllFilters = () => {
  //   setSelectedNames([]);
  //   setSelectedGroups([]);
  //   setCurrentPage(1); // Reset to first page when clearing filters
  // };

  const clearAllFilters = () => {
    const clearMeta: ActionMeta<SelectOption> = {
      action: 'clear',
      removedValues: [],
      name: '',
      option: undefined,
      removedValue: undefined
    };

    if (onNameFilterChange) onNameFilterChange([], clearMeta);
    if (onGroupFilterChange) onGroupFilterChange([], clearMeta);
    setCurrentPage(1);
  };

  const getEngagementScoreColor = (score: number) => {
    if (score >= 90) return 'text-green-600';
    if (score >= 75) return 'text-blue-600';
    if (score >= 60) return 'text-yellow-600';
    return 'text-red-600';
  };

  const getRankIcon = (rank: number, normalizedScore: number, allScores: number[]) => {
    // Get unique scores sorted in descending order from all data
    const uniqueScores = [...new Set(allScores)].sort((a, b) => b - a);

    // Get top two scores from all data
    const topTwoScores = uniqueScores.slice(0, 2);

    // Check if the score matches one of the top two scores
    if (normalizedScore === topTwoScores[0]) {
      return (
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div className="flex items-center justify-center w-8 h-8 bg-green-100 rounded-full cursor-help">
                <span className="text-lg font-bold text-green-600">1</span>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                className="bg-gray-800 text-white p-2 rounded text-sm"
                side="top"
              >
                <p>First Rank - Top Performer ({normalizedScore}%)</p>
                <Tooltip.Arrow className="fill-gray-800" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      );
    } else if (normalizedScore === topTwoScores[1]) {
      return (
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div className="flex items-center justify-center w-8 h-8 bg-purple-100 rounded-full cursor-help">
                <span className="text-lg font-bold text-purple-600">2</span>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                className="bg-gray-800 text-white p-2 rounded text-sm"
                side="top"
              >
                <p>Second Rank - Outstanding Performer ({normalizedScore}%)</p>
                <Tooltip.Arrow className="fill-gray-800" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      );
    } else {
      return (
        <div className="flex items-center justify-center w-8 h-8">
          <span className="text-sm text-gray-600">{rank}</span>
        </div>
      );
    }
  };

  const uniqueScores = [...new Set(sortedData.map(d => d.normalizedScore))].sort((a, b) => b - a);

  // Store the top 2 highest scores
  const top2Scores = uniqueScores.slice(0, 2);

  // Keep track of how many non-top-3 items
  let nextRank = 3;

  const calculateRank = (rank: number, normalizedScore: number, allScores: number[]) => {
    // Get the original top scores (not from filtered data)
    const originalTopScores = [...new Set(userMetrics.map(u => u.normalizedScore))]
      .sort((a, b) => b - a)
      .slice(0, 2);

    // Sort all users by normalized score
    const sortedData = [...userMetrics].sort((a, b) => b.normalizedScore - a.normalizedScore);

    const user = sortedData[rank - 1];
    if (!user) return rank;

    const userPosition = sortedData.findIndex(u => u.name === user.name);

    // Find user's original position
    const originalPosition = userMetrics.findIndex(u =>
      u.name === user.name &&
      u.normalizedScore === user.normalizedScore
    );

    // Count total number of special ranks in the entire dataset
    const specialRanksCount = userMetrics.filter(u =>
      u.normalizedScore === originalTopScores[0] ||
      u.normalizedScore === originalTopScores[1]
    ).length;

    // If user has a top 2 score and was in the original top positions
    if (normalizedScore === originalTopScores[0] &&
      originalPosition < userMetrics.filter(u => u.normalizedScore === originalTopScores[0]).length) {
      return 1;
    }
    if (normalizedScore === originalTopScores[1] &&
      originalPosition < userMetrics.filter(u => u.normalizedScore >= originalTopScores[1]).length) {
      return 2;
    }

    if (userPosition === -1) return rank;

    const rank1Count = sortedData.filter(u => u.normalizedScore === originalTopScores[0]).length;
    const rank2Count = sortedData.filter(u => u.normalizedScore === originalTopScores[1]).length;

    return userPosition + 1 - (rank1Count + rank2Count) + 2;
  };

  const getRankBackground = (normalizedScore: number, allScores: number[]) => {
    // Get unique scores sorted in descending order from all data
    const uniqueScores = [...new Set(allScores)].sort((a, b) => b - a);
    // Get top two scores from all data
    const topTwoScores = uniqueScores.slice(0, 2);

    if (normalizedScore === topTwoScores[0]) return 'bg-amber-100 hover:bg-amber-200';
    if (normalizedScore === topTwoScores[1]) return 'bg-slate-200 hover:bg-slate-300';
    return 'bg-white hover:bg-yellow-50';
  };

  // Calculate filtered metrics
  const filteredMetrics = useMemo(() => {
    // Total participants is the number of filtered users
    const totalParticipants = filteredData.length;


    // Total events is the sum of topics (meetings) participated by filtered users
    const uniqueEvents = meetings
      .filter(meeting =>
        meeting.attendees.some(attendee =>
          filteredData.some(user => user.email === attendee.attendeeEmail)
        )
      );

    const totalEvents = uniqueEvents?.length ?? 0;

    // Calculate averages from filtered data
    const averages = filteredData.length > 0
      ? {
        acceptance: Math.round(
          filteredData.reduce((acc, user) =>
            acc + (user.acceptedCount / user.invitedCount * 100), 0
          ) / filteredData.length
        ),
        attendance: Math.round(
          filteredData.reduce((acc, user) =>
            acc + (user.attendedCount / user.invitedCount * 100), 0
          ) / filteredData.length
        )
      }
      : { acceptance: 0, attendance: 0 };

    return {
      totalEvents,
      totalParticipants,
      avgAcceptance: averages.acceptance,
      avgAttendance: averages.attendance
    };
  }, [filteredData]);

  const { totalEvents, totalParticipants, avgAcceptance, avgAttendance } = filteredMetrics;

  // Get all scores for rank calculation
  const allScores = useMemo(() =>
    [...new Set(userMetrics.map(user => user.normalizedScore))]
      .sort((a, b) => b - a),
    [userMetrics]
  );

  //Prepare data for display
  const leaderboardData = filteredData.map((user) => ({
    ...user,
    rank: calculateRank(user.rank, user.normalizedScore, allScores),
  }));

  const exportData = filteredData.map(user => ({
    rank: calculateRank(user.rank, user.normalizedScore, allScores),
    name: user.name,
    group: user.group,
    acceptedScore: Math.round((user.acceptedCount / user.invitedCount) * 100) + '%',
    participationScore: Math.round((user.attendedCount / user.invitedCount) * 100) + '%',
    acceptedButNotParticipated: Math.round((user.acceptedCount - user.attendedCount) / user.invitedCount * 100) + '%',
    aARatio: Math.round((user.attendedCount / user.acceptedCount) * 100) + '%',
    engagementScore: Math.round(user.normalizedScore) + '%'
  }));

  const getUserTopics = (userEmail: string, meetings: IMeetingInfo[]): Array<{
    topic: string;
    invited: number;
    accepted: number;
    attended: number;
    notAttended: number;
  }> => {
    const topicsMap = new Map();

    meetings
      .filter(meeting => meeting.attendees.some(attendee => attendee.attendeeEmail === userEmail))
      .forEach(meeting => {
        if (!meeting.interestName) return;

        const attendee = meeting.attendees.find(a => a.attendeeEmail === userEmail);
        if (!attendee) return;

        if (!topicsMap.has(meeting.interestName)) {
          topicsMap.set(meeting.interestName, {
            topic: meeting.interestName,
            invited: 0,
            accepted: 0,
            attended: 0,
            notAttended: 0
          });
        }

        const stats = topicsMap.get(meeting.interestName);
        stats.invited++;
        if (attendee.didAccept) stats.accepted++;
        if (attendee.didAttend) stats.attended++;
        if (attendee.didAccept && !attendee.didAttend) stats.notAttended++;
      });

    return Array.from(topicsMap.values()).sort((a, b) => a.topic.localeCompare(b.topic));
  };

  return (
    <div style={style}>
      <div className="p-4 sm:p-6 max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow-lg p-6 relative">
          {/* Header */}
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-gray-900">
              Attendance Leaderboard
            </h2>
            <div className="flex items-center gap-4">
              {/* <DownloadMenu /> */}
            </div>
          </div>

          {/* Summary Section */}
          <div className="flex flex-wrap gap-4 mb-6 justify-center">
            <MetricCard
              title="Total Events"
              value={totalEvents}
              icon={<Calendar className="w-5 h-5 text-blue-600 group-hover:text-blue-700 group-hover:rotate-12 transition-transform" />}
            />
            <MetricCard
              title="Total Participants"
              value={totalParticipants}
              icon={<Users className="w-5 h-5 text-blue-600 group-hover:text-blue-700 group-hover:rotate-12 transition-transform" />}
            />
            <MetricCard
              title="Average Acceptance"
              value={`${avgAcceptance}%`}
              icon={<BarChart3 className="w-5 h-5 text-blue-600 group-hover:text-blue-700 group-hover:rotate-12 transition-transform" />}
            />
            <MetricCard
              title="Average Attendance"
              value={`${avgAttendance}%`}
              icon={<CheckCircle2 className="w-5 h-5 text-blue-600 group-hover:text-blue-700 group-hover:rotate-12 transition-transform" />}
            />
          </div>

          {/* Search and Table Container */}
          <div className="mt-6">
            {<div className="grid grid-cols-1 md:grid-cols-[minmax(0,1fr),minmax(0,1fr)] lg:grid-cols-[minmax(0,1fr),minmax(0,1fr)] gap-4 mb-4">
              <div className="relative">
                <div className="absolute left-3 top-1/2 -translate-y-1/2 z-10 pointer-events-none">
                  <Search className="h-4 w-4 text-gray-500" />
                </div>
                <Select<SelectOption, true>
                  isMulti
                  value={selectedNames}
                  onChange={handleNameFilterChange}
                  options={nameOptions}
                  className="text-sm"
                  styles={customStyles}
                  components={SelectComponents}
                  placeholder="Search by name..."
                  isClearable
                />
              </div>
              <div className="flex flex-col md:flex-row gap-4">
                <div className="relative flex-1">
                  <div className="absolute left-3 top-1/2 -translate-y-1/2 z-10 pointer-events-none">
                    <Users className="h-4 w-4 text-gray-500" />
                  </div>
                  <Select<SelectOption, true>
                    isMulti
                    value={selectedGroups}
                    onChange={handleGroupFilterChange}
                    options={groupOptions}
                    className="text-sm"
                    styles={customStyles}
                    components={SelectComponents}
                    placeholder="Search by group..."
                    isClearable
                  />
                </div>
                {(selectedNames.length > 0 || selectedGroups.length > 0) && (
                  <button
                    onClick={clearAllFilters}
                    className="h-[42px] md:self-end whitespace-nowrap flex items-center justify-center gap-1 px-4 py-1.5 text-sm text-red-600 hover:text-red-700 bg-red-50 hover:bg-red-100 rounded-md transition-colors duration-200"
                  >
                    <XCircle className="w-4 h-4" />
                    Clear Filters
                  </button>
                )}

                <LeaderboardExport
                  data={exportData}
                  userMetrics={userMetrics}
                  metrics={filteredMetrics} />
              </div>
            </div>}

            {/* Table with responsive overflow handling */}
            <div className="w-full overflow-x-auto lg:overflow-x-hidden">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
                  <table className="min-w-full table-auto border-collapse">
                    <thead className="bg-blue-500">
                      <tr>
                        <th scope="col" className={`${getHeaderClass('rank')} border-r border-l border-t border-b border-gray-300 px-2 py-0.5`} onClick={() => handleSort('rank')}>
                          <div className="flex items-center gap-2">
                            Rank {getSortIcon('rank')}
                          </div>
                        </th>
                        <th scope="col" className={`${getHeaderClass('name')} border-r border-t border-b border-gray-300 px-3 py-0.5`} onClick={() => handleSort('name')}>
                          <div className="flex items-center gap-12">
                            <div className="flex items-center">
                              NAME
                            </div>
                            {getSortIcon('name')}
                          </div>
                        </th>
                        <th scope="col" className={`${getHeaderClass('group')} border-r border-t border-b border-gray-300 px-3 py-0.5`} onClick={() => handleSort('group')}>
                          <div className="flex items-center gap-12">
                            <div className="flex items-center">
                              GROUP
                            </div>
                            <div className="flex items-center gap-1">
                              {getSortIcon('group')}
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger asChild>
                                    <Info className="w-4 h-4 text-white/70 cursor-help" />
                                  </Tooltip.Trigger>
                                  <Tooltip.Portal>
                                    <Tooltip.Content
                                      className="bg-gray-800 text-white p-2 rounded text-sm"
                                      side="top"
                                    >
                                      <div className="space-y-2">
                                        <p>Groups represent different regions and departments:</p>
                                        <ul className="list-disc pl-4 space-y-1">
                                          <li>Global: Worldwide teams (Finance, Legal, HR)</li>
                                          <li>Regional: Area-specific teams (EMEA, LATAM, NA, APAC)</li>
                                          <li>Department: Functional teams (Sales, Marketing, Product, Support)</li>
                                        </ul>
                                      </div>
                                      <Tooltip.Arrow className="fill-gray-800" />
                                    </Tooltip.Content>
                                  </Tooltip.Portal>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </div>
                          </div>
                        </th>
                        <th scope="col" className={`${getHeaderClass('acceptedCount')} border-r border-t border-b border-gray-300 max-w-[130px] px-6 py-0.5`} onClick={() => handleSort('acceptedCount')}>
                          <div className="flex items-center gap-2">
                            <div className="flex items-center gap-1">
                              ACCEPTED SCORE {getSortIcon('acceptedCount')}
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger asChild>
                                    <Info className="w-4 h-4 text-white/70 cursor-help" />
                                  </Tooltip.Trigger>
                                  <Tooltip.Portal>
                                    <Tooltip.Content
                                      className="bg-gray-800 text-white p-2 rounded text-sm"
                                      side="top"
                                    >
                                      <p>Formula: Accepted Count / Invited Count × 100%</p>
                                      <p className="mt-1">Shows the percentage of meetings accepted by the user</p>
                                      <p className="mt-1">Example: If 10 meetings were accepted out of 15 invited, this would show 66.67% (10/15 × 100% = 66.67%).</p>
                                      <Tooltip.Arrow className="fill-gray-800" />
                                    </Tooltip.Content>
                                  </Tooltip.Portal>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </div>
                          </div>
                        </th>
                        <th scope="col" className={`${getHeaderClass('attendedCount')} border-r border-t border-b border-gray-300 max-w-[160px] px-6 py-0.5`} onClick={() => handleSort('attendedCount')}>
                          <div className="flex items-center gap-2">
                            <div className="flex items-center gap-1">
                              PARTICIPATION SCORE {getSortIcon('attendedCount')}
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger asChild>
                                    <Info className="w-4 h-4 text-white/70 cursor-help" />
                                  </Tooltip.Trigger>
                                  <Tooltip.Portal>
                                    <Tooltip.Content
                                      className="bg-gray-800 text-white p-2 rounded text-sm"
                                      side="top"
                                    >
                                      <p>Formula: Attended Count / Invited Count × 100%</p>
                                      <p className="mt-1">Shows the percentage of meetings attended by the user</p>
                                      <p className="mt-1">Example: If 8 meetings were attended out of 15 invited, this would show 53.33% (8/15 × 100% = 53.33%).</p>
                                      <Tooltip.Arrow className="fill-gray-800" />
                                    </Tooltip.Content>
                                  </Tooltip.Portal>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </div>
                          </div>
                        </th>
                        <th scope="col" className={`${getHeaderClass('acceptedNotParticipated')} border-r border-t border-b border-gray-300 max-w-[190px] px-6 py-0.5`} onClick={() => handleSort('acceptedNotParticipated')}>
                          <div className="flex items-center gap-2">
                            <div className="flex items-center gap-1">
                              <b>Accepted But Not Participated</b> {getSortIcon('acceptedNotParticipated')}
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger asChild>
                                    <Info className="w-4 h-4 text-white/70 cursor-help" />
                                  </Tooltip.Trigger>
                                  <Tooltip.Portal>
                                    <Tooltip.Content
                                      className="bg-gray-800 text-white p-2 rounded text-sm"
                                      side="top"
                                    >
                                      <p>Formula: Accepted Count - Attended Count</p>
                                      <p className="mt-1">Shows the number of meetings that were accepted but not attended.</p>
                                      <p className="mt-1">Example: If 10 meetings were accepted and 8 were attended, this would show 2 (10 - 8 = 2).</p>
                                      <Tooltip.Arrow className="fill-gray-800" />
                                    </Tooltip.Content>
                                  </Tooltip.Portal>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </div>
                          </div>
                        </th>
                        <th scope="col" className={`${getHeaderClass('attendedVsAcceptedRatio')} border-r border-t border-b border-gray-300 px-6 py-0.5`} onClick={() => handleSort('attendedVsAcceptedRatio')}>
                          <div className="flex items-center gap-2">
                            <div className="flex items-center gap-1">
                              A/A Ratio {getSortIcon('attendedVsAcceptedRatio')}
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger asChild>
                                    <Info className="w-4 h-4 text-white/70 cursor-help" />
                                  </Tooltip.Trigger>
                                  <Tooltip.Portal>
                                    <Tooltip.Content
                                      className="bg-gray-800 text-white p-2 rounded text-sm"
                                      side="top"
                                    >
                                      <p>Formula: (Attended Count / Accepted Count) × 100%</p>
                                      <p className="mt-1">Shows the percentage of accepted meetings that were actually attended.</p>
                                      <p className="mt-1">Example: If 8 meetings were attended out of 10 accepted, this would show 80% (8/10 × 100% = 80%).</p>
                                      <Tooltip.Arrow className="fill-gray-800" />
                                    </Tooltip.Content>
                                  </Tooltip.Portal>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </div>
                          </div>
                        </th>
                        <th scope="col" className={`${getHeaderClass('normalizedScore')} border-r border-t border-b border-gray-300 px-6 py-0.5`} onClick={() => handleSort('normalizedScore')}>
                          <div className="flex items-center gap-2">
                            <div className="flex items-center gap-1">
                              Engagement Score {getSortIcon('normalizedScore')}
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger asChild>
                                    <Info className="w-4 h-4 text-white/70 cursor-help" />
                                  </Tooltip.Trigger>
                                  <Tooltip.Portal>
                                    <Tooltip.Content
                                      className="bg-gray-800 text-white p-2 rounded text-sm space-y-2"
                                      side="top"
                                    >
                                      <p>Shows the overall engagement score based on acceptance and attendance</p>
                                      <p>Total Score = (Accepted × 1) + (Attended × 2) - (Not Participated × 1)</p>
                                      <p>Final Engagement Score = Total Score / (Total Invited Count / 3) × 100%</p>
                                      <p>Example: If 10 meetings were accepted, 8 were attended, and 2 were not participated, the total score would be (10 × 1) + (8 × 2) - (2 × 1) = 24.</p>
                                      <p>The final engagement score would be 24 / 10 / 3 × 100 = 80%</p>
                                      <Tooltip.Arrow className="fill-gray-800" />
                                    </Tooltip.Content>
                                  </Tooltip.Portal>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {currentData.map((item, index) => {
                        // Get background color based on top two scores from all data
                        const rankBackground = getRankBackground(item.normalizedScore, userMetrics.map(d => d.normalizedScore));

                        return (
                          <motion.tr
                            key={item.email}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: index * 0.05 }}
                            className={`${rankBackground} transition-colors duration-150 ease-in-out cursor-pointer`}
                          >
                            <td className="px-2 py-0.5 whitespace-nowrap border-l border-r border-b border-gray-300">
                              <div className="flex items-center justify-center">
                                {/* {getRankIcon(item.rank, item.normalizedScore, userMetrics.map(d => d.normalizedScore))} */}
                                <div className="flex items-center justify-center">
                                  <RankBadge
                                    rank={calculateRank(item.rank, item.normalizedScore, userMetrics.map(d => d.normalizedScore))}
                                    badgeRank={
                                      item.normalizedScore === originalTopScores[0] ? 1 :
                                        item.normalizedScore === originalTopScores[1] ? 2 : 0
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="px-3 py-0.5 whitespace-nowrap max-w-[160px] border-r border-b border-gray-300">
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger asChild>
                                    <div className="cursor-help">
                                      <div className="text-sm font-medium text-gray-900 text-left pl-0">
                                        {item.name}
                                      </div>
                                    </div>
                                  </Tooltip.Trigger>
                                  <Tooltip.Portal>
                                    <Tooltip.Content
                                      className="bg-gray-800 text-white p-2 rounded text-sm max-w-md min-w-[360px]"
                                      side="top"
                                    >
                                      <div className="space-y-2">
                                        <p><span className="font-medium">Email:</span> {item.email}</p>
                                        <div>
                                          <p className="font-medium mb-1">
                                            Topics: <span className="text-white-500 font-bold">({getUserTopics(item.email, meetings).length})</span>
                                          </p>
                                          <div className="max-h-[150px] overflow-y-auto custom-scrollbar">
                                            <div className="space-y-1">
                                              {getUserTopics(item.email, meetings).map((topicData, index) => (
                                                <div key={index} className="flex items-center justify-between">
                                                  <div className="flex items-center gap-2 min-w-[200px]">
                                                    <span className="text-gray-400 w-6 text-right font-medium">{index + 1}.</span>
                                                    <span className="text-white">{topicData.topic}</span>
                                                  </div>
                                                  {/* Use the below detailed status of each topic code incase needed in future */}
                                                  {/* <div className="flex gap-3 ml-2">
                                                    <div className="flex items-center gap-1" title={`Invited: ${topicData.invited} meetings`}>
                                                      <span className="text-blue-500">
                                                        {topicData.invited > 0 ? <Check className="w-4 h-4" /> : <X className="w-4 h-4" />}
                                                      </span>
                                                      <span className="text-xs text-blue-600 font-medium">{topicData.invited}</span>
                                                    </div>

                                                    <div className="flex items-center gap-1" title={`Accepted: ${topicData.accepted} meetings`}>
                                                      <span className="text-yellow-500">
                                                        {topicData.accepted > 0 ? <Check className="w-4 h-4" /> : <X className="w-4 h-4" />}
                                                      </span>
                                                      <span className="text-xs text-yellow-600 font-medium">{topicData.accepted}</span>
                                                    </div>

                                                    <div className="flex items-center gap-1" title={`Attended: ${topicData.attended} meetings`}>
                                                      <span className="text-green-500">
                                                        {topicData.attended > 0 ? <Check className="w-4 h-4" /> : <X className="w-4 h-4" />}
                                                      </span>
                                                      <span className="text-xs text-green-600 font-medium">{topicData.attended}</span>
                                                    </div>

                                                    <div className="flex items-center gap-1 mr-3" title={`Not Attended: ${topicData.notAttended} meetings`}>
                                                      <span className="text-red-500">
                                                        {topicData.notAttended > 0 ? <X className="w-4 h-4" /> : <Check className="w-4 h-4" />}
                                                      </span>
                                                      <span className="text-xs text-red-600 font-medium">{topicData.notAttended}</span>
                                                    </div>
                                                  </div> */}
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                        <p><span className="font-medium">Timezone:</span> {
                                          item.group.includes("EMEA") ? "GMT+1 (Central European Time)" :
                                            item.group.includes("APAC") ? "GMT+8 (Singapore Time)" :
                                              item.group.includes("LATAM") ? "GMT-3 (São Paulo Time)" :
                                                item.group.includes("NA") ? "GMT-5 (Eastern Time)" :
                                                  item.group === "Global Leadership" ? "GMT (London Time)" :
                                                    "GMT-8 (Pacific Time)"
                                        }</p>
                                      </div>
                                      <Tooltip.Arrow className="fill-gray-800" />
                                    </Tooltip.Content>
                                  </Tooltip.Portal>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </td>
                            <td className="px-3 py-0.5 whitespace-nowrap max-w-[160px] border-r border-b border-gray-300">
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger asChild>
                                    <div className="cursor-help w-full">
                                      <div className="text-sm text-gray-900 text-left pl-0">
                                        {item.group}
                                      </div>
                                    </div>
                                  </Tooltip.Trigger>
                                  <Tooltip.Portal>
                                    <Tooltip.Content
                                      className="bg-gray-800 text-white p-2 rounded text-sm max-w-xs"
                                      side="top"
                                    >
                                      <div className="space-y-2">
                                        <p className="font-medium mb-1">Region Details:</p>
                                        {(() => {
                                          const group = item.group || 'Other';
                                          const regionInfo = {
                                            EMEA: "Europe, Middle East, and Africa",
                                            APAC: "Asia Pacific",
                                            LATAM: "Latin America",
                                            NA: "North America",
                                            Global: "Global Operations",
                                            Engineering: "Global Engineering",
                                            Product: "Product Development",
                                            Support: "Customer Support",
                                            Financial: "Financial Operations",
                                            Cloud: "Cloud Services",
                                            Enterprise: "Enterprise Solutions",
                                            Government: "Government Services"
                                          };

                                          const getRegionDetails = (group: string) => {
                                            if (group.includes("EMEA")) return regionInfo.EMEA;
                                            if (group.includes("APAC")) return regionInfo.APAC;
                                            if (group.includes("LATAM")) return regionInfo.LATAM;
                                            if (group.includes("NA")) return regionInfo.NA;
                                            if (group.includes("Global")) return regionInfo.Global;
                                            if (group.includes("Engineering")) return regionInfo.Engineering;
                                            if (group.includes("Product")) return regionInfo.Product;
                                            if (group.includes("Support")) return regionInfo.Support;
                                            if (group.includes("Financial")) return regionInfo.Financial;
                                            if (group.includes("Cloud")) return regionInfo.Cloud;
                                            if (group.includes("Enterprise")) return regionInfo.Enterprise;
                                            if (group.includes("Government")) return regionInfo.Government;
                                            return "Other Operations";
                                          };

                                          const details = getRegionDetails(group);
                                          return (
                                            <div>
                                              <p>{details}</p>
                                              {group.includes("EMEA") && (
                                                <p className="mt-1 text-xs">Countries: UK, France, Germany, UAE, South Africa</p>
                                              )}
                                              {group.includes("APAC") && (
                                                <p className="mt-1 text-xs">Countries: Japan, Singapore, Australia, India, China</p>
                                              )}
                                              {group.includes("LATAM") && (
                                                <p className="mt-1 text-xs">Countries: Brazil, Mexico, Argentina, Colombia, Chile</p>
                                              )}
                                              {group.includes("NA") && (
                                                <p className="mt-1 text-xs">Countries: USA, Canada</p>
                                              )}
                                            </div>
                                          );
                                        })()}
                                      </div>
                                      <Tooltip.Arrow className="fill-gray-800" />
                                    </Tooltip.Content>
                                  </Tooltip.Portal>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </td>
                            <td className="px-6 py-0.5 whitespace-nowrap text-center border-r border-b border-gray-300">
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger asChild>
                                    <div className="cursor-help flex justify-center">
                                      <div className="text-sm font-semibold text-gray-700">
                                        {Math.round((item.acceptedCount / item.invitedCount) * 100)}%
                                      </div>
                                    </div>
                                  </Tooltip.Trigger>
                                  <Tooltip.Portal>
                                    <Tooltip.Content
                                      className="bg-gray-800 text-white p-2 rounded text-sm"
                                      side="top"
                                    >
                                      <p>{item.acceptedCount} meetings accepted / {item.invitedCount} meetings invited</p>
                                      <Tooltip.Arrow className="fill-gray-800" />
                                    </Tooltip.Content>
                                  </Tooltip.Portal>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </td>
                            <td className="px-6 py-0.5 whitespace-nowrap text-center border-r border-b border-gray-300">
                              <div className="flex justify-center items-center w-full">
                                <Tooltip.Provider>
                                  <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                      <span className="text-gray-600 font-medium">
                                        {Math.round((item.attendedCount / item.invitedCount) * 100)}%
                                      </span>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                      <Tooltip.Content
                                        className="bg-gray-800 text-white p-2 rounded text-sm"
                                        side="top"
                                      >
                                        <p>{item.attendedCount} meetings attended / {item.invitedCount} meetings invited</p>
                                        <Tooltip.Arrow className="fill-gray-800" />
                                      </Tooltip.Content>
                                    </Tooltip.Portal>
                                  </Tooltip.Root>
                                </Tooltip.Provider>
                              </div>
                            </td>
                            <td className="px-6 py-0.5 whitespace-nowrap text-center border-r border-b border-gray-300 max-w-[180px]">
                              <Tooltip.Provider>
                                <Tooltip.Root>
                                  <Tooltip.Trigger asChild>
                                    <div className="text-sm font-semibold text-gray-700 flex justify-center">
                                      {item.acceptedNotParticipated}%
                                    </div>
                                  </Tooltip.Trigger>
                                  <Tooltip.Portal>
                                    <Tooltip.Content
                                      className="bg-gray-800 text-white p-2 rounded text-sm"
                                      side="top"
                                    >
                                      <p>{item.acceptedCount} meetings accepted but {item.acceptedCount - item.attendedCount} meetings not attended</p>
                                      <Tooltip.Arrow className="fill-gray-800" />
                                    </Tooltip.Content>
                                  </Tooltip.Portal>
                                </Tooltip.Root>
                              </Tooltip.Provider>
                            </td>
                            <td className="px-6 py-0.5 whitespace-nowrap text-sm text-gray-500 text-center border-r border-b border-gray-300">
                              <div className="text-sm font-medium text-gray-600 flex justify-center">
                                <Tooltip.Provider>
                                  <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                      <span className="text-gray-600 font-medium">{Math.round(item.attendedVsAcceptedRatio)}%</span>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                      <Tooltip.Content
                                        className="bg-gray-800 text-white p-2 rounded text-sm"
                                        side="top"
                                      >
                                        <p>{item.attendedCount} attended meetings / {item.acceptedCount} accepted meetings</p>
                                        <Tooltip.Arrow className="fill-gray-800" />
                                      </Tooltip.Content>
                                    </Tooltip.Portal>
                                  </Tooltip.Root>
                                </Tooltip.Provider>
                              </div>
                            </td>
                            <td className="px-6 py-0.5 whitespace-nowrap text-center border-r border-b border-gray-300">
                              <span className={`text-sm font-medium ${item.normalizedScore >= 90
                                ? 'text-green-600'
                                : item.normalizedScore >= 70
                                  ? 'text-blue-600'
                                  : 'text-red-600'
                                }`}>
                                <Tooltip.Provider>
                                  <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                      <span>{item.normalizedScore}%</span>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                      <Tooltip.Content
                                        className="bg-gray-800 text-white p-2 rounded text-sm space-y-2"
                                        side="top"
                                      >
                                        <p>Accepted: {item.acceptedCount} × 1</p>
                                        <p>Attended: {item.attendedCount} × 2</p>
                                        <p>Not Participated: {item.acceptedCount - item.attendedCount} × (-1)</p>
                                        <p>Total Score: {item.acceptedCount + (item.attendedCount * 2) - (item.acceptedCount - item.attendedCount)}</p>
                                        <p>Final Engagement Score = {item.acceptedCount + (item.attendedCount * 2) - (item.acceptedCount - item.attendedCount)} / {item.invitedCount} / 3 × 100 = {Math.round(item.normalizedScore)}%</p>
                                        <Tooltip.Arrow className="fill-gray-800" />
                                      </Tooltip.Content>
                                    </Tooltip.Portal>
                                  </Tooltip.Root>
                                </Tooltip.Provider>
                              </span>
                            </td>
                          </motion.tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Pagination */}
            <div className="flex items-center justify-between px-4 py-3 bg-white">
              {/* Mobile pagination */}
              <div className="sm:hidden w-full">
                <div className="flex flex-col space-y-3">
                  <div className="text-sm text-gray-700 text-center">
                    Showing {startIndex + 1} to {Math.min(endIndex, sortedData.length)} of {sortedData.length}
                  </div>
                  <nav className="isolate flex items-center justify-center gap-1" aria-label="Pagination">
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center rounded-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeft className="h-5 w-5" />
                    </button>

                    {getPageNumbers().map((pageNumber, index) => (
                      pageNumber === 'dot' ? (
                        <span
                          key={`dot-${index}`}
                          className="relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 min-w-[32px] justify-center"
                        >
                          ...
                        </span>
                      ) : (
                        <button
                          key={pageNumber}
                          onClick={() => typeof pageNumber === 'number' ? setCurrentPage(pageNumber) : undefined}
                          className={`relative inline-flex items-center px-3 py-2 text-sm font-semibold ${typeof pageNumber === 'number' && pageNumber === currentPage
                            ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                            : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                            }`}
                        >
                          {pageNumber}
                        </button>
                      )
                    ))}

                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="relative inline-flex items-center rounded-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRight className="h-5 w-5" />
                    </button>
                  </nav>
                </div>
              </div>

              {/* Desktop pagination */}
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{startIndex + 1}</span> to{' '}
                    <span className="font-medium">{Math.min(endIndex, sortedData.length)}</span> of{' '}
                    <span className="font-medium">{sortedData.length}</span> results
                  </p>
                </div>
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeft className="h-5 w-5" />
                  </button>

                  {getPageNumbers().map((pageNumber, index) => (
                    pageNumber === 'dot' ? (
                      <span
                        key={`dot-${index}`}
                        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300"
                      >
                        ...
                      </span>
                    ) : (
                      <button
                        key={pageNumber}
                        onClick={() => typeof pageNumber === 'number' ? setCurrentPage(pageNumber) : undefined}
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${typeof pageNumber === 'number' && pageNumber === currentPage
                          ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                          : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                          }`}
                      >
                        {pageNumber}
                      </button>
                    )
                  ))}

                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRight className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            </div>
          </div >
        </div >
        {
          showDownloadMenu && (
            <div
              className="fixed z-50"
              style={{
                top: `${downloadMenuPosition.top}px`,
                left: `${downloadMenuPosition.left}px`
              }}
            >
            </div>
          )
        }
        {
          showDownloadMenu && (
            <div
              className="fixed inset-0 z-40"
              onClick={() => {
                setShowDownloadMenu(false);
                setSelectedUserForDownload(null);
              }}
            />
          )
        }
      </div>
    </div>
  );
};

export default Leaderboard;