import { FluentProvider, Spinner, teamsLightTheme } from '@fluentui/react-components'
import { HashRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { useTeamsUserCredential } from '@microsoft/teamsfx-react'
import { useEffect } from 'react'
import Privacy from './Privacy'
import TermsOfUse from './TermsOfUse'
import { app } from "@microsoft/teams-js";
import { TeamsFxContext } from './Context'
import config from '../config'
import Preference from '../pages/preferences/Index'
import { AuthProvider } from '../context/Auth'
import Tab from './Tab'
import Notification from '../pages/notifications'
import ManageInterest from '../pages/manageInterest/index'
import Reports from '../pages/reports'
import { enableMapSet } from 'immer'
import { pino } from 'pino'
import TrendingTopics from '../pages/preferences/TrendingTopics'
import AttendanceReports from '../pages/reports/attendanceReport'
import ManageGroup from '../pages/manageGroup'
//import './index.css';


export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  })

  useEffect(() => {
    loading &&
      app.initialize().then(() => {
        // Hide the loading indicator.
        app.notifySuccess();
      });
  }, [loading]);

  enableMapSet() //for zustand + immer

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <FluentProvider style={{ fontFamily: 'Segoe UI' }} theme={teamsLightTheme}>
        <AuthProvider>
          <Router>
            {loading ? (
              <Spinner
                size="huge"
                labelPosition="below"
                label="Loading..."
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            ) : (
              <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/tab" element={<Tab />} />
                <Route path="/preference" element={<Preference />} />
                <Route path="/notifications" element={<Notification />} />
                <Route path="/manageInterest" element={<ManageInterest />} />
                <Route path="/manageGroup" element={<ManageGroup />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/attendanceReports" element={<AttendanceReports />} />
                <Route path="/trending" element={<TrendingTopics />} />
                <Route path="*" element={<Navigate to={'/tab'} />}></Route>
              </Routes>
            )}
          </Router>
        </AuthProvider>
      </FluentProvider>
    </TeamsFxContext.Provider>
  )
}

export const logger = pino({
  level: 'debug',
  browser: {
    serialize: true,
    asObject: true,
  },
  transport: {
    target: 'pino-pretty',
  },
})
