import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { ProfileInfo } from '../services/graph/profile'
import { isAccessTokenExpired } from '../services/oauth/jwt'
import { useAppStateStore } from '../store'
import { useData } from "@microsoft/teamsfx-react";
import { app } from "@microsoft/teams-js";
import { TeamsFxContext } from '../components/Context'

export interface IAuthContext {
  profileInfo?: ProfileInfo
  accessTokenFromTeamsUserCredential?: string
}

const authContext: IAuthContext = {}

const AuthContext = createContext(authContext)
const CACHE_ACCESS_TOKEN_KEY = 'Watercooler:AccessToken'

const getAccessTokenFromCache = () => {
  console.debug('get access token from browser session')
  const accessTokenFromCache = localStorage.getItem(CACHE_ACCESS_TOKEN_KEY)
  return accessTokenFromCache
}
var accessTokenFromTeamsUserCredential = "";

export function AuthProvider({ children }: { children: ReactNode }) {
  const [profileInfo, setProfileInfo] = useState<ProfileInfo>()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const appState = useAppStateStore()
  const { teamsUserCredential } = useContext(TeamsFxContext);

  const getAccessTokenFromTeamsUserCredential = async () => {
    if (teamsUserCredential) {
      // const userInfo = await teamsUserCredential.getUserInfo();
      const authScopes = [
        'User.ReadBasic.All',
        'Calendars.ReadWrite',
        'MailboxSettings.Read']
      await teamsUserCredential?.login(authScopes)
      //const ssoToken = (await teamsUserCredential.getToken(""))?.token
      //accessTokenFromTeamsUserCredential = ssoToken!;
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i); // Get the key at index i
        if (key && /accesstoken/i.test(key)) { // Ensure `key` is not null
          console.log("Matching Key:", key);

          const value = sessionStorage.getItem(key); // Retrieve the value
          if (value) { // Ensure `value` is not null

            try {
              const parsedValue = JSON.parse(value);
              if (parsedValue && parsedValue.secret) {
                console.log("Secret Key:", parsedValue.secret);
                accessTokenFromTeamsUserCredential = parsedValue.secret;
              }
            } catch (e) {
              console.error("Failed to parse JSON:", e);
            }
          }
        }
      }

      appState.setAccessToken(accessTokenFromTeamsUserCredential);

      if (!appState.identity.accessToken) {
        appState.setAccessToken(accessTokenFromTeamsUserCredential);
      }


      return accessTokenFromTeamsUserCredential;
    }
  }

  const hubName = useData(async () => {
    await app.initialize();
    const context = await app.getContext();
    return context.app.host.name;
  })?.data;

  const signIn = async () => {
    try {
      if (appState.identity.accessToken && !isAccessTokenExpired(appState.identity.accessToken)) {
        setIsAuthenticated(true)
        return
      }

      const accessTokenFromCache = getAccessTokenFromCache()
      if (accessTokenFromCache && !isAccessTokenExpired(accessTokenFromCache)) {
        appState.setAccessToken(accessTokenFromCache)
        setIsAuthenticated(true)
        return
      }
      const accessTokenFromTeamsUserCredentialResult = await getAccessTokenFromTeamsUserCredential();
      if (accessTokenFromTeamsUserCredentialResult) {
        appState.setAccessToken(accessTokenFromTeamsUserCredentialResult)
        localStorage.setItem(CACHE_ACCESS_TOKEN_KEY, accessTokenFromTeamsUserCredentialResult)
        setIsAuthenticated(true)
        return
      }
      console.log("error in setting up token ")

    }
    catch (err) {
      console.log("error is ")
      console.log(err)
    }
    finally {
    }
  }
  useEffect(() => {
    signIn();
  }, [teamsUserCredential, signIn])

  const providerValue: IAuthContext = {
    profileInfo,
    accessTokenFromTeamsUserCredential
  }

  return <AuthContext.Provider value={providerValue}>{isAuthenticated && children}</AuthContext.Provider>
}
